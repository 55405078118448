import { useState } from "react";

const useForm = (callback, validate, defaultState) => {
  const [inputs, setInputs] = useState(defaultState);
  const [touched, setTouched] = useState(false);

  const handleInputChange = event => {
    event.persist();
    if (touched) {
      validate();
    }
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    setTouched(true);
    validate();
    callback();
  };

  return { handleInputChange, handleSubmit, inputs };
};

export default useForm;
