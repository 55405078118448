import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import useForm from "../hooks/useForm";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

import "../css/global.css";
import "../css/contact.css";

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [submission, setSubmission] = useState("");

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const submitForm = () => {
    if (Object.keys(errors).length === 0) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact-form", ...inputs }),
      })
        .then(() => {
          setSubmission("Thanks - your message has been sent.");
        })
        .catch(error => {
          setSubmission(
            "There was an error sending the form. Please try again later."
          );
        });
    }
  };

  const validate = () => {
    setErrors({});

    if (!inputs.fullname) {
      return setErrors(errors => ({
        ...errors,
        fullname: "Please enter a name",
      }));
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) {
      return setErrors(errors => ({
        ...errors,
        email: "Invalid email address",
      }));
    }

    if (!inputs.message) {
      return setErrors(errors => ({
        ...errors,
        message: "Please add a message",
      }));
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(
    submitForm,
    validate,
    {
      fullname: "",
      email: "",
      message: "",
    }
  );

  const { instagram } = useSiteMetadata();

  return (
    <Layout>
      <SEO title="Contact" />
      <section className="contact">
        {submission === "" && (
          <>
            <h2>
              For all enquiries please use the form below or, alternatively,
              message me on{" "}
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                Instagram
              </a>
              :
            </h2>
            <form
              id="contact-form"
              data-netlify="true"
              name="contact-form"
              netlify-honeypot="important-field"
              onSubmit={handleSubmit}
            >
              <label htmlFor="fullname">Name</label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                placeholder="Required"
                aria-required="true"
                value={inputs.fullname}
                onChange={handleInputChange}
              />
              <div className="error">{errors?.fullname}</div>

              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Required"
                aria-required="true"
                value={inputs.email}
                onChange={handleInputChange}
              />
              <div className="error">{errors?.email}</div>

              <label htmlFor="message" className="margined">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                type="text"
                wrap="soft"
                rows="10"
                value={inputs.message}
                onChange={handleInputChange}
              ></textarea>
              <div className="error">{errors?.message}</div>

              <input
                type="text"
                id="important"
                name="important-field"
                className="hidden"
              />
              <button type="submit" id="quote-submit">
                Get in touch
              </button>
              <div className="error" id="form-error"></div>
            </form>
          </>
        )}
        {submission !== "" && <h2>{submission}</h2>}
      </section>
    </Layout>
  );
};

export default Contact;
